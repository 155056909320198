import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { mobile, mobileMedium, tabletMedium } from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import definition from "@/game-val/definition.mjs";
import Container from "@/shared/ContentContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { classNames } from "@/util/class-names.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function PatchNotes() {
  const { t } = useTranslation();
  const {
    contentful: { related },
  } = useSnapshot(readState);
  const [first, ...patchnotes] = useMemo(
    () =>
      related
        .filter((i) => !/release\sdate/i.test(i.title))
        .filter((i) =>
          ["valorant", "patchNotes"].every((t) => i.tags.find((j) => j === t)),
        ),
    [related],
  );
  if (!Array.isArray(patchnotes) || !patchnotes.length) return null;
  return (
    <Container>
      <PageHeader
        title={t("valorant:title.patchNotes", "VALORANT Patch Notes")}
      />
      <a
        href={`/${definition.shortName}/article/${first.id}`}
        className="flex column gap-2"
        style={{ marginTop: "var(--sp-4)" }}
      >
        <img src={first.imageUrl} alt={first.title} className="br w-full" />
        <h2 className="type-h3">
          {t("common:latestPatchNotes", "Latest Patch Notes")}: {first.title}
        </h2>
      </a>
      <h2 style={{ marginTop: "var(--sp-10)" }}>
        {t("contentful:pastPatchNotes", "Past Patch Notes")}
      </h2>
      <div className={classNames(cssMaps())?.className}>
        {patchnotes.map((map) => {
          return (
            <a
              href={`/${definition.shortName}/article/${map.id}`}
              key={map.id}
              className="flex column gap-2"
            >
              <img src={map.imageUrl} alt={map.title} className="br w-full" />
              <p className="type-subtitle1">{map.title}</p>
            </a>
          );
        })}
      </div>
    </Container>
  );
}

const cssMaps = () =>
  css({
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr)",
    gap: "var(--sp-4)",
    [tabletMedium]: {
      gridTemplateColumns: "repeat(3,1fr)",
    },
    [mobile]: {
      gridTemplateColumns: "repeat(2,1fr)",
    },
    [mobileMedium]: {
      gridTemplateColumns: "1fr",
    },
  });

export function meta() {
  return {
    title: ["valorant:title.patchNotes", "VALORANT Patch Notes"],
    description: [
      "valorant:meta.patchNotes.description",
      "Discover the latest VALORANT patch notes for detailed updates on new features, gameplay improvements, and bug fixes. Stay ahead in your game with the most recent changes and enhancements for an optimal Minecraft experience.",
    ],
    subtitle: true,
  };
}
